/*
 * Plugin: Web Slide Navigation System
 * Demo Link: https://uxwing.com/webslide/
 * Author: UXWing
 * License: http://codecanyon.net/licenses/standard
*/
/* ======== Find Below Table of Content Points to Go Relevant Section  =========
[Table of contents DESKTOP]
Desktop Base CSS
Desktop Main Menu CSS
- Desktop Search Bar
Desktop Drop Down Menu CSS
Desktop Mega Menus CSS
-> Desktop Half Menu CSS
-> Desktop HTML Form Menu CSS
Desktop Extra CSS

[Table of contents MOBILE ]
Mobile Menu Change Brake Point
Mobile Base CSS
Mobile Main Menu CSS
Mobile Slide Down Links CSS
Mobile Mega Menus CSS
Mobile Header CSS
 -> Mobile Search Bar
 -> Mobile Toggle Menu icon (X ICON)
Mobile Overlay/Drawer CSS
Mobile Sub Menu Expander Arrows
Mobile Extra CSS
Extra @Media Query
===============================================================================*/
/* ================== Desktop Base CSS  ================== */
.wsmenu html,
.wsmenu body,
.wsmenu iframe,
.wsmenu h1,
.wsmenu h2,
.wsmenu h3,
.wsmenu h4,
.wsmenu h5,
.wsmenu h6 {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-family: Helvetica, sans-serif;
  font-smoothing: antialiased;
  font-smooth: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: subpixel-antialiased;
  font-smooth: subpixel-antialiased;
}
.wsmenu .cl {
  clear: both;
}
.wsmenu img,
object,
embed,
video {
  border: 0 none;
  max-width: 100%;
}
.wsmenu a:focus {
  outline: none;
}
.wsmenu:before,
.wsmenu:after {
  content: "";
  display: table;
}
.wsmenu:after {
  clear: both;
}
/* ================== Desktop Main Menu CSS ================== */
/* Top Fixed Effects */
.wsmainfull.wsfixed {
  position: fixed;
  background-color: #004a93;
  -webkit-transition: height 0.4s ease, opacity 0.3s ease;
  -o-transition: height 0.4s ease, opacity 0.3s ease;
  transition: height 0.4s ease, opacity 0.3s ease;
  border-bottom: none;
  z-index: 1000;
}
.wsmainfull.wsfixed .wsmenu > .wsmenu-list > li > a {
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 37px;
  -webkit-transition: margin 0.4s ease, padding 0.4s ease;
  -o-transition: margin 0.4s ease, padding 0.4s ease;
  transition: margin 0.4s ease, padding 0.4s ease;
}
.wsmainfull.wsfixed .desktoplogo {
  width: 200px;
  padding: 10px 0px;
  -webkit-transition: margin 0.4s ease, padding 0.4s ease;
  -o-transition: margin 0.4s ease, padding 0.4s ease;
  transition: margin 0.4s ease, padding 0.4s ease;
}
.wsmainfull.wsfixed .wsmenu > .wsmenu-list > li > a .wsarrow:after {
  top: 23px;
  border-top-color: #d5d5d5;
}
.wsmainfull.wsfixed .wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch {
  margin-top: 7px;
}
.wsmainfull.wsfixed .wsmenu > .wsmenu-list > li.carticon em.roundpoint {
  top: 9px;
}
.wsmainfull.wsfixed .wsmenu > .wsmenu-list > li > ul.sub-menu {
  top: 52px;
}
.wsmainfull.wsfixed .wsmenu > .wsmenu-list > li > .wsmegamenu {
  top: 52px;
}
/* Top Fixed Effects */
.wsmenucontainer {
  overflow: hidden;
}
.wsmainfull {
  width: 100%;
  background-size: cover;
  background-image: url(/Assets/Styles/webslidemenu/images/bg01.jpg);
  background-repeat: no-repeat;
}
.wsmainwp {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
}
.desktoplogo {
  padding: 16px 0px 0px 0px;
  margin: 0;
  float: left;
}
.desktoplogo img {
  width: 100%;
}
.desktoplogo a:hover {
  opacity: 0.8;
}
.wsmenu {
  font-family: Helvetica, sans-serif;
  color: #fff;
  font-size: 14px;
  padding: 0;
  width: 76%;
  float: right;
  display: block;
}
.wsmenu > .wsmenu-list {
  text-align: left;
  margin: 0 auto 0 auto;
  width: 100%;
  display: block;
  padding: 0;
}
.wsmenu > .wsmenu-list > li {
  text-align: center;
  display: block;
  padding: 0;
  margin: 0;
  float: left;
}
.wsmenu > .wsmenu-list > li > a {
  display: block;
  padding: 11px 39px 11px 18px;
  line-height: 50px;
  text-decoration: none;
  position: relative;
  border-bottom: solid 3px transparent;
}
.wsmenu > .wsmenu-list > li:last-child > a {
  border-right: 0px;
}
.wsmenu > .wsmenu-list > li a.menuhomeicon {
  padding-left: 29px;
  padding-right: 29px;
}
.wsmenu > .wsmenu-list > li.carticon a {
  padding: 11px 15px 11px 23px;
  text-align: center;
}
.wsmenu > .wsmenu-list > li.carticon a .fa {
  font-size: 16px;
}
.wsmenu > .wsmenu-list > li.carticon em.roundpoint {
  display: block;
  position: absolute;
  top: 12px;
  right: 0px;
  z-index: 50;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: -4px;
  margin-left: 3px;
  padding-top: 1px;
  padding-right: 3px;
  padding-bottom: 1px;
  padding-left: 3px;
  min-width: 16px;
  height: 16px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  background-color: #ff6600;
  color: #ffffff;
  vertical-align: baseline;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  opacity: 1;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 1.5s;
  transition-property: opacity;
}
.wsmenu > .wsmenu-list > li.carticon > a > .mobiletext {
  display: none;
}
.wsmenu > .wsmenu-list > li > a .fa {
  display: inline-block;
  font-size: 14px;
  line-height: inherit;
  margin-right: 12px;
  vertical-align: top;
}
.wsmenu > .wsmenu-list > li > a .fa.fa-home {
  margin-right: 0px;
  font-size: 15px;
}
.wsmenu > .wsmenu-list > li > a .wsarrow:after {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-top: 4px solid;
  content: "";
  float: right;
  right: 17px;
  height: 0;
  margin: 0 0 0 14px;
  position: absolute;
  text-align: right;
  top: 33px;
  width: 0;
}
/* Desktop Search Bar */
.wsmenu > .wsmenu-list > li.rightmenu {
  float: right;
}
.wsmenu > .wsmenu-list > li.rightmenu a {
  border-right: none;
  font-size: 20px;
  text-shadow: 1px 1px 2px #000, 0 0 1em #f7feb8;
}
.wsmenu > .wsmenu-list > li.rightmenu a .fa {
  font-size: 15px;
}
.wsmenu > .wsmenu-list > li.rightmenu {
  float: right;
}
.wsmenu > .wsmenu-list > li.rightmenu:before,
.wsmenu-list > li.rightmenu:after {
  content: "";
  display: table;
}
.wsmenu > .wsmenu-list > li.rightmenu:after {
  clear: both;
}
.wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch {
  float: right;
  width: 300px;
  height: 39px;
  position: relative;
  margin: 16px 0px 0px 0px;
}
.wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch .searchicon {
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}
.wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch input {
  position: relative;
  float: right;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  text-indent: 15px;
  height: 39px;
  z-index: 2;
  outline: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  color: #333;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
  font-size: 12px;
}
.wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch input:focus {
  color: #fff;
  width: 340px;
}
.wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch input:focus ~ .btnstyle .fa {
  color: #fff;
  opacity: 1;
}
.wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch input:focus ~ .searchicon {
  opacity: 1;
  z-index: 3;
  color: #FFFFFF;
}
.wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch .btnstyle {
  top: 0px;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  line-height: 30px;
  z-index: 1;
  cursor: pointer;
  color: #fff;
  z-index: 1000;
  background-color: transparent;
  border: solid 0px;
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}
.wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch .btnstyle .fa {
  line-height: 37px;
  margin: 0;
  padding: 0;
  text-align: center;
  opacity: 0.3;
}
.wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch .btnstyle:hover .fa {
  opacity: 0.35;
}
.wsmenu > .wsmenu-list > li.rightmenu {
  zoom: 1;
}
/* ================== Desktop Drop Down Menu CSS ================== */
.wsmenu > .wsmenu-list > li > ul.sub-menu {
  position: absolute;
  top: 75px;
  z-index: 1000;
  margin: 0px;
  padding: 0px;
  min-width: 190px;
  background-color: #fff;
  border: solid 1px #eeeeee;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li {
  position: relative;
  margin: 0px;
  padding: 0px;
  display: block;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
  background-image: none;
  color: #666666;
  border-right: 0 none;
  text-align: left;
  display: block;
  line-height: 22px;
  padding: 8px 12px;
  text-transform: none;
  font-size: 13px;
  letter-spacing: normal;
  border-right: 0px solid;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover {
  text-decoration: none;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a > .fa {
  margin-right: 9px;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu {
  min-width: 220px;
  position: absolute;
  left: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border: solid 1px #eeeeee;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li {
  position: relative;
  margin: 0px;
  padding: 0px;
  display: block;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a {
  background-image: none;
  color: #666666;
  border-right: 0 none;
  text-align: left;
  display: block;
  line-height: 22px;
  padding: 8px 12px;
  text-transform: none;
  font-size: 13px;
  letter-spacing: normal;
  border-right: 0px solid;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
  text-decoration: none;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a > .fa {
  margin-right: 9px;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu {
  min-width: 220px;
  position: absolute;
  left: 100%;
  top: 0;
  margin: 0px;
  list-style: none;
  padding: 0px;
  background-color: #fff;
  border: solid 1px #eeeeee;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li {
  position: relative;
  margin: 0px;
  padding: 0px;
  display: block;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
  background-image: none;
  color: #666666;
  border-right: 0 none;
  text-align: left;
  display: block;
  line-height: 22px;
  padding: 8px 12px;
  text-transform: none;
  font-size: 13px;
  letter-spacing: normal;
  border-right: 0px solid;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
  text-decoration: none;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a > .fa {
  margin-right: 9px;
}
/* ================== Desktop Mega Menus CSS  ================== */
.wsmenu > .wsmenu-list > li > .wsmegamenu {
  width: 100%;
  left: 0px;
  position: absolute;
  top: 75px;
  color: #000;
  z-index: 1000;
  margin: 0px;
  text-align: left;
  padding: 14px 20px;
  font-size: 15px;
  border: solid 2px #eeeeee;
  background-color: #fff;
  box-shadow: 0 1px 8px rgba(6, 33, 63, 0.13);
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .title {
  border-bottom: 1px solid #CCC;
  font-size: 14px;
  padding: 9px 5px 9px 0px;
  font-size: 17px;
  color: #424242;
  margin: 0px 0px 7px 0px;
  text-align: left;
  height: 39px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .carousel-control-next {
  opacity: 0.8;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .carousel-control-prev {
  opacity: 0.8;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .carousel-caption {
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  height: 31px;
  left: 0;
  padding: 7px 0;
  right: 0;
  width: 100%;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .wsmwnutxt {
  width: 100%;
  font-size: 14px;
  text-align: justify;
  line-height: 22px;
  color: #424242;
  margin-top: 10px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .fluid-width-video-wrapper {
  margin-bottom: 10px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-align: left;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a {
  line-height: 18px;
  border-right: none;
  text-align: left;
  padding: 6px 0px;
  background: #fff;
  background-image: none;
  color: #666666;
  border-right: 0 none;
  display: block;
  background-color: #fff;
  color: #337ab7;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu li .fa {
  margin-right: 5px;
  text-align: center;
  width: 18px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu li a:hover {
  background: transparent;
  text-decoration: underline;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li .fa {
  font-size: 11px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu li .fa {
  margin-right: 5px;
  text-align: center;
  width: 18px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .mrgtop {
  margin-top: 15px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .show-grid div {
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #dbdbdb;
  border: 1px solid #e7e7e7;
  color: #6a6a6a;
  margin: 2px 0px;
  font-size: 13px;
}
/*= Desktop Half Menu CSS =*/
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
  width: 40%;
  right: auto;
  left: auto;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfdiv {
  width: 35%;
  right: auto;
  left: auto;
}
/*= Desktop HTML Form Menu CSS =*/
.wsmenu > .wsmenu-list > li > .wsmegamenu .menu_form {
  width: 100%;
  display: block;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .menu_form input[type="text"] {
  width: 100%;
  border: 1px solid #e2e2e2;
  color: #000;
  font-size: 13px;
  padding: 8px 5px;
  margin-bottom: 8px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .menu_form textarea {
  width: 100%;
  border: 1px solid #e2e2e2;
  color: #000;
  font-size: 13px;
  padding: 8px 5px;
  margin-bottom: 8px;
  min-height: 122px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .menu_form input[type="submit"] {
  width: 25%;
  display: block;
  height: 32px;
  float: right;
  border: none;
  margin-right: 15px;
  cursor: pointer;
  background-color: #e1e1e1;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .menu_form input[type="button"] {
  width: 25%;
  display: block;
  height: 32px;
  float: right;
  border: none;
  cursor: pointer;
  background-color: #e1e1e1;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .carousel-inner .item img {
  width: 100%;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .carousel-caption {
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  height: 31px;
  left: 0;
  padding: 7px 0;
  right: 0;
  width: 100%;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .typography-text {
  padding: 0px 0px;
  font-size: 14px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .typography-text ul {
  padding: 0px 0px;
  margin: 0px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .typography-text p {
  text-align: justify;
  line-height: 24px;
  color: #656565;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .typography-text ul li {
  display: block;
  padding: 2px 0px;
  line-height: 22px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .typography-text ul li a {
  color: #656565;
}
/* ================== Desktop Extra CSS ================== */
.wsmobileheader {
  display: none;
}
.overlapblackbg {
  opacity: 0;
  visibility: hidden;
}
.wsmenu .wsmenu-click {
  display: none;
}
.wsmenu .wsmenu-click02 {
  display: none;
}
.hometext {
  display: none;
}
/*==============================================================================
                              Start Mobile CSS
===============================================================================*/
/* ================== Mobile Menu Change Brake Point ================== */
@media only screen and (max-width: 991px) {
  /* ================== Mobile Base CSS ================== */
  html {
    overflow: hidden;
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }
  body {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  body.wsactive {
    overflow: hidden;
  }
  /* ================== Mobile Main Menu CSS ================== */
  .desktoplogo {
    display: none;
  }
  .wsmainfull {
    height: 0;
  }
  .wsactive .wsmenu > .wsmenu-list > li > a.active {
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  }
  .wsactive .wsmenu > .wsmenu-list > li:hover > a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  }
  .wsmenucontainer {
    background-attachment: local;
    background-position: 33% 0%;
  }
  .wsmenu {
    width: 100%;
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    left: 0;
    overflow-y: hidden;
    padding: 0;
    top: 0;
    visibility: hidden;
    position: fixed;
    margin: 0px;
  }
  .wsmenu > .wsmenu-list {
    height: auto;
    min-height: 100%;
    width: 240px;
    background: #fff;
    padding-bottom: 0;
    margin-left: -240px;
    display: block;
    text-align: center;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .wsmenu > .wsmenu-list > li {
    width: 100%;
    display: block;
    float: none;
    border-right: none;
    background-color: transparent;
    position: relative;
    white-space: inherit;
    clear: right;
  }
  .wsmenu > .wsmenu-list > li > a {
    padding: 12px 32px 12px 17px;
    font-size: 14px;
    text-align: left;
    border-right: solid 0px;
    background-color: transparent;
    color: #666666;
    line-height: 25px;
    border-bottom: 1px solid;
    border-bottom-color: rgba(0, 0, 0, 0.13);
    position: static;
  }
  .wsmenu > .wsmenu-list > li > a .fa.fa-home {
    display: none;
  }
  .wsmenu > .wsmenu-list > li.carticon > a > .mobiletext {
    display: inline-block;
  }
  .wsmenu > .wsmenu-list > li.carticon a {
    text-align: left;
    padding: 12px 32px 12px 17px;
  }
  .wsmenu > .wsmenu-list > li.carticon a .fa {
    font-size: 14px;
  }
  .wsmenu > .wsmenu-list > li.carticon em.roundpoint {
    top: 9px;
    right: auto;
    left: 23px;
    background-color: #ff6600;
  }
  .wsmenu > .wsmenu-list > li a.menuhomeicon {
    padding-left: 17px;
    padding-right: 32px;
  }
  .wsmenu > .wsmenu-list > li > a > .fa {
    font-size: 16px;
    color: #bfbfbf;
  }
  .wsmenu > .wsmenu-list > li > a .wsarrow:after {
    display: none;
  }
  .wsmenu > .wsmenu-list > li:hover > a {
    background-color: rgba(0, 0, 0, 0.08);
    text-decoration: none;
  }
  .wsmenu > .wsmenu-list > li > a > .hometext {
    display: inline-block;
  }
  /* ================== Mobile Slide Down Links CSS ================== */
  .wsmenu > .wsmenu-list > li > ul.sub-menu {
    display: none;
    position: relative;
    top: 0px;
    background-color: #fff;
    border: none;
    padding: 0px;
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    transition: inherit;
    -webkit-transform-style: flat;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
    line-height: 20px;
    font-size: 13px;
    padding: 10px 0px 10px 16px;
    color: #383838;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li span + a {
    padding-right: 30px;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a > .fa {
    display: none;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover {
    background-color: #e7e7e7;
    color: #666666;
    text-decoration: underline;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu li:hover > a {
    background-color: #e7e7e7;
    color: #666666;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu {
    width: 100%;
    position: static;
    left: 100%;
    top: 0;
    display: none;
    margin: 0px;
    padding: 0px;
    border: solid 0px;
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    transition: inherit;
    -webkit-transform-style: flat;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li {
    margin: 0px 0px 0px 0px;
    padding: 0px;
    position: relative;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a {
    line-height: 20px;
    font-size: 13px;
    padding: 10px 0px 10px 26px;
    color: #383838;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li span + a {
    padding-right: 30px;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a > .fa {
    display: none;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
    background-color: #e7e7e7;
    color: #666666;
    text-decoration: underline;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a.active {
    color: #000;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li:hover > a {
    color: #000;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu {
    width: 100%;
    position: static;
    left: 100%;
    top: 0;
    display: none;
    margin: 0px;
    padding: 0px;
    border: solid 0px;
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    transition: inherit;
    -webkit-transform-style: flat;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li {
    margin: 0px 0px 0px 0px;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
    line-height: 20px;
    font-size: 13px;
    padding: 10px 0px 10px 34px;
    color: #383838;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li span + a {
    padding-right: 30px;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a > .fa {
    display: none;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
    background-color: #e7e7e7;
    color: #666666;
    text-decoration: underline;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a.active {
    color: #000;
  }
  /* ================== Mobile Mega Menus CSS  ================== */
  .wsmenu > .wsmenu-list > li > .wsmegamenu {
    color: #666666;
    display: none;
    position: relative;
    top: 0px;
    padding: 10px 0px;
    border: solid 0px;
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    transition: inherit;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    -webkit-transform-style: flat;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
    width: 100%;
    margin: 0px;
    padding: 5px 0px 10px 0px;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu .title {
    color: #666666;
    font-size: 15px;
    padding: 10px 8px 10px 0px;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu > ul {
    width: 100%;
    margin: 0px;
    padding: 0px;
    font-size: 13px;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu > ul > li > a {
    padding: 9px 14px;
    line-height: normal;
    font-size: 13px;
    background-color: #e7e7e7;
    color: #666666;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu > ul > li > a:hover {
    background-color: #000000;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title {
    line-height: 26px;
    color: #666666;
    margin: 0px;
    font-size: 15px;
    padding: 7px 0px;
    background-color: transparent;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfdiv {
    width: 100%;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu .menu_form {
    padding: 5px 0px 62px 0px;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu .show-grid div {
    margin: 0px;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu .menu_form input[type="button"] {
    width: 46%;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu .menu_form input[type="submit"] {
    width: 46%;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu .menu_form textarea {
    min-height: 100px;
  }
  /* ================== Mobile Header CSS ================== */
  .wsmobileheader {
    width: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10002;
    height: 54px;
    background-color: #eaecf0;
    text-align: center;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  }
  .wsactive .wsmobileheader {
    margin-left: 240px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .wsmobileheader .smllogo {
    display: inline-block;
    margin-top: 12px;
  }
  /* Mobile Search Bar*/
  .wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch {
    width: 86%;
    margin: 7% 7%;
    padding: 0px;
  }
  .wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch input {
    border-radius: 0px;
  }
  .wsmenu > .wsmenu-list > li.rightmenu > .topmenusearch input:focus {
    width: 100%;
  }
  .callusbtn {
    color: #848080;
    font-size: 21px;
    position: absolute;
    right: 5px;
    top: 0px;
    transition: all 0.4s ease-in-out 0s;
    z-index: 102;
    padding: 11px 14px;
  }
  .callusbtn .fa {
    vertical-align: top;
    margin-top: 4px;
  }
  .callusbtn:hover .fa {
    color: #a9a9a9;
  }
  /* Mobile Toggle Menu icon (X ICON) */
  .wsanimated-arrow {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 102;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .wsanimated-arrow {
    cursor: pointer;
    padding: 16px 35px 16px 0px;
    margin: 7px 0 0 15px;
  }
  .wsanimated-arrow span,
  .wsanimated-arrow span:before,
  .wsanimated-arrow span:after {
    cursor: pointer;
    height: 2px;
    width: 17px;
    background: #848080;
    position: absolute;
    display: block;
    content: '';
  }
  .wsanimated-arrow span:before {
    top: -7px;
    width: 23px;
  }
  .wsanimated-arrow span:after {
    bottom: -7px;
    width: 17px;
  }
  .wsanimated-arrow span,
  .wsanimated-arrow span:before,
  .wsanimated-arrow span:after {
    transition: all 500ms ease-in-out;
  }
  .wsactive .wsanimated-arrow span:after {
    width: 23px;
  }
  .wsactive .wsanimated-arrow span {
    background-color: transparent;
  }
  .wsactive .wsanimated-arrow span:before,
  .wsactive .wsanimated-arrow.active span:after {
    top: 7px;
  }
  .wsactive .wsanimated-arrow span:before {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 0px;
  }
  .wsactive .wsanimated-arrow span:after {
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  /* ================== Mobile Overlay/Drawer CSS ================== */
  .overlapblackbg {
    right: 0;
    width: calc(100% - 240px);
    height: 100vh;
    min-height: 100%;
    position: fixed;
    top: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
  }
  .wsactive .wsmenu .overlapblackbg {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -ms-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
  }
  .wsmenucontainer {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .wsactive .wsmenucontainer {
    margin-left: 240px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .wsactive .wsmenu {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    visibility: visible;
    z-index: 1000;
    top: 0;
  }
  .wsactive .wsmenu > .wsmenu-list {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    margin-left: 0;
  }
  /* ================== Mobile Sub Menu Expander Arrows  ================== */
  .wsmenu > .wsmenu-list > li > .wsmenu-click {
    height: 49px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }
  .wsmenu > .wsmenu-list > li .wsmenu-click.ws-activearrow > i {
    transform: rotate(180deg);
  }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i {
    display: block;
    height: 23px;
    width: 25px;
    margin-top: 14px;
    margin-right: 10px;
    background-size: 25px;
    font-size: 21px;
    color: rgba(255, 255, 255, 0.5);
    float: right;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li .wsmenu-click02 {
    height: 41px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li .wsmenu-click02 > i {
    display: block;
    height: 23px;
    width: 25px;
    margin: 8px 6px 8px 6px;
    background-size: 25px;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.25);
    float: right;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li .wsmenu-click02 > i.wsmenu-rotate {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  /*End Media Query*/
}
/* Extra @Media Query*/
@media only screen and (min-width: 992px) and (max-width: 1162px) {
  .desktoplogo {
    margin-left: 12px;
  }
  .wsmenu > .wsmenu-list > li > a > .wsarrow {
    display: none;
  }
  .wsmenu > .wsmenu-list > li > a {
    padding-left: 12px;
    padding-right: 12px;
  }
  .wsmenu > .wsmenu-list > li a.menuhomeicon {
    padding-left: 22px;
    padding-right: 22px;
  }
}
