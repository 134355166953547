/* Desktop Main Menu Color */
.wsmainfull {
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: solid 1px;
  border-bottom-color: rgba(0, 0, 0, 0.14);
}
.wsmenu > .wsmenu-list {
  background-color: #004a93;
}
/* Mobile Header */
.wsmobileheader {
  background-color: rgba(255, 255, 255, 0.95);
}
.wsmenu > .wsmenu-list > li > a {
  color: #fff;
}
.wsmenu > .wsmenu-list > li > a .wsarrow:after {
  border-top-color: #fff;
}
.wsmenu > .wsmenu-list > li > a:hover .wsarrow:after {
  border-top-color: #fff;
}
.wsmenu > .wsmenu-list > li > a.active .wsarrow:after {
  border-top-color: #fff;
}
.wsmenu > .wsmenu-list > li:hover > a .wsarrow:after {
  border-top-color: #fff;
}
.wsmenu > .wsmenu-list > li > a > .fa {
  color: #fff;
}
.wsmenu > .wsmenu-list > li > a.active .fa {
  color: #fff;
}
.wsmenu > .wsmenu-list > li:hover > a > .fa {
  color: #fff;
}
.wsmenu > .wsmenu-list > li > a:hover .fa {
  color: #fff;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .menu_form input[type="submit"] {
  background-color: #004a93;
  color: #fff;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .menu_form input[type="button"] {
  background-color: #004a93;
  color: #fff;
}
.wsmenu > .wsmenu-list > li > a.active {
  border-bottom-color: #004a93;
  color: #fff;
}
.wsmenu > .wsmenu-list > li:hover > a {
  border-bottom-color: #ff6600;
  color: #fff;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li:hover > a {
  background-color: #004a93;
  color: #fff;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li:hover > a {
  background-color: #004a93;
  color: #fff;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li:hover a {
  background-color: #004a93;
  color: #fff;
}
